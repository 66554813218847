<template>
  <div>
    <div class="login-box">
      <img class="login-box-cover-img" src="../assets/image/admin_woyaoyou_bg_login@2x.png" />
      <div class="login-main">
        <div>
          <div class="text-center">
            <img style="width: 228px; height: 92px;" v-if="siteInfo != null" :src="siteInfo.logo_white_admin_login" />
          </div>
          <div class="login-form-box">
            <div class="text-center ft24 ftw600 cl-main">欢迎登录</div>
            <div class="login-input-item mt32 flex alcenter space">
              <a-input v-model="form.username" prefix="登录帐号" :maxLength="11" class="username"></a-input>
            </div>
            <div class="login-input-item mt24 flex alcenter space">
              <a-input type="password" v-model="form.password" prefix="登录密码"  class="pwd"></a-input>
            </div>
            <div class="mt32 login-btn">
              <a-button style="width: 100%;" @click="loginAct" type="primary">立即登录</a-button>
            </div>

          </div>
          <br><br><br><br><br><br>
          <hr>
          <div class="icp"  > <a href="http://beian.miit.gov.cn/" target="_blank"> 粤ICP备2023029839号 </a> </div>
        </div>

      </div>

    </div>
<!--    <div class="icp"> <a href="http://beian.miit.gov.cn/"  target="_blank"> 粤ICP备2023029839号 </a>-->
<!--&lt;!&ndash;      <link rel="canonical"  href="http://beian.miit.gov.cn" title="xs">&ndash;&gt;-->
<!--    </div>-->
  </div>
</template>

<script>
	export default {
		data() {
			return {
				loading:false,
				code:'',
				form:{
					username:'',
					password:'',
				},
				siteInfo:null
			}
		},
		created() {
			this.getCode();
			this.getSiteInfo();
		},
	
		mounted() {
			//绑定事件
			window.addEventListener('keydown', this.keyDown);
		},
		methods:{
			getSiteInfo(){
				this.$http.api('platform/siteInfo').then(res=>{
					this.siteInfo = res.site;
				}).catch(res=>{
					console.log(res);
				});
			},
			getCode(){
				this.$http.api('platform/admin/getCode',{
					
				}).then(res=>{
					this.code = res.code;
				}).catch(res=>{
					console.log(res);
				});
				
			},
			loginAct(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/admin/login',{
					token:this.code,
					username:this.form.username,
					password:this.form.password,
				}).then(res=>{
					localStorage.setItem('admin-token',res.auth_token);
					this.$message.success('登录成功',1,()=>{
						this.$router.push('/home');
					})
				}).catch(res=>{
					console.log(res);
				}).finally(()=>{
					this.loading=false;
				})
				
			},
			keyDown(e) {
				//如果是回车则执行登录方法
				if (e.keyCode == 13) {
					this.loginAct();
				}
			},
		}
    ,
		destroyed() {
			window.removeEventListener('keydown', this.keyDown, false);
		}
	}
</script>

<style>
	.login-box {
		width: 100vw;
		height: 100vh;
		background: linear-gradient(135deg, #4772FF 0%, #5851FF 100%);
		position: relative;
	}
	.login-main{
		width: 100vw;
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
	}
	.login-box-cover-img {
		position: absolute;
		bottom: 0px;
		width: 100vw;
	}
	.login-form-box{
		width: 620px;
		height: 462px;
		background: #FFFFFF;
		box-shadow: 0px 4px 32px -8px rgba(0, 0, 0, 0.02);
		border-radius: 24px;
		margin-top: 36px;
		padding: 48px 40px;
	}
	.login-input-item{
		width: 100%;
		height: 50px;
		border-radius: 30px;
		border: 1px solid #DCDFE6;
		padding: 0 24px;
	}
	.login-input-item .ant-input{
		border:none;
		font-size: 16px;
	}
	.login-input-item .ant-input-affix-wrapper .ant-input:not(:first-child){
		padding-left: 88px;
	}
	.login-input-item .username{
		width: 100%;
	}
	.login-input-item .pwd{
		width: 200px;
	}
	.login-input-item .ant-input-affix-wrapper .ant-input-prefix{
		color:#A6AEC2;
		font-size: 16px;
	}
	.login-input-item .ant-input:focus{
		border:none;
		box-shadow:none;
	}
	.login-btn .ant-btn{
		height: 50px;
		border-radius: 25px;
		font-size: 16px;
	}
  .icp {
    /*position: absolute;
    bottom: 0;
    right: 0;*/
    margin: 10px 0;
    width: 100%;
    height: 36px;
    white-space: pre;
    text-align: center;
    color: white;
    z-index: 1000;
  }
  .icp > a {
    color: white;
    text-decoration: none;
  }
  .icp > a:hover {
    color: aqua;
    text-decoration: none;
  }

</style>
